import Service from './Service'

/**
 * EventLogger API service.
 *
 */
export default class EventLogger extends Service {
	create(payload) {
		return this.request.post('v4/events', payload)
	}

	healthCheck(activePartnerId, refreshIntervalInMinutes) {
		return this.request.get(
			`v4/partners/${activePartnerId}/calls/published?since=${refreshIntervalInMinutes}m`
		)
	}
}
