import stateFactory from './state'
import * as helpers from '@/store/helpers'
import {
	ADD_ITEMS,
	UPDATE_CALL,
	ADD_ITEMS_TO_FRONT,
	ADD_NOTE_EVENT,
	ADD_RECENT_CALL,
	ADD_NOTE_TO_CALL,
	ADD_TO_FILTER,
	UPDATE_TO_FILTER,
	COMPLETE_CALL,
	DISMISS_CALL,
	REMOVE_NOTE_FROM_CALL,
	REMOVE_ALL_RECENT_CALLS,
	REMOVE_BY_ID,
	REMOVE_CALL_TYPE_FILTER,
	REMOVE_DATE_RANGE_FILTER,
	REMOVE_RECENT_CALL,
	REMOVE_SEARCH_FILTER,
	REMOVE_STATUS_FILTERS,
	CLEAR_ALL_FILTERS,
	RESET,
	RESET_FILTERS,
	SET_ENDS_TIME_FILTER,
	SET_STARTS_TIME_FILTER,
	SET_CALL_PROVIDER_FILTERS,
	SET_CALL_TYPE_FILTERS,
	SET_FILTER_IDS,
	SET_FILTER_META,
	SET_INCOMPLETE_CALL_COUNT,
	SET_ITEMS,
	SET_META,
	SET_NON_URGENT_CALL_FILTER,
	SET_SEARCH_FILTER,
	SET_STATUS_FILTERS,
	SET_URGENT_CALL_FILTER,
	UPDATE_CALL_NOTE,
	UPDATE_CALL_ATTRIBUTE,
	SET_LOADING,
} from '@/store/mutation-types'
import {
	COMPLETE_FILTER,
	DISMISS_FILTER,
	INCOMPLETE_FILTER,
	NOTED_FILTER,
} from '@/config/calls'
import moment from 'moment'

/**
 * The module's state mutators.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	// Mutation to clear all filters
	[CLEAR_ALL_FILTERS](state) {
		state.filters = {
			complete: null,
			ends: null,
			fallback: null,
			ids: [],
			new: null,
			providers: [],
			search: '',
			starts: null,
			statuses: [],
			types: [],
			urgent: false,
			nonUrgent: false,
		}
	},

	/**
	 * Add the given model(s) to the store.
	 *
	 * @param {Object} state
	 * @param {Array} models
	 */
	[ADD_ITEMS](state, models) {
		helpers.add(state, models)
	},

	/**
	 * Add the call note created event.
	 *
	 * @param {Object} state
	 * @param {Object} event
	 * @return {void}
	 */
	[ADD_NOTE_EVENT](state, event) {
		const call = state.items[event.call_id]
		const events = (call.events || []).concat(event)

		state.items[call.id] = {
			...call,
			events,
		}
	},

	[UPDATE_CALL](state, call) {
		state.items[call.id] = {
			...call,
		}
	},

	/**
	 * Add/unshift the given model(s) to the store.
	 *
	 * @param {Object} state
	 * @param {Array} models
	 */
	[ADD_ITEMS_TO_FRONT](state, models) {
		helpers.addToFront(state, models)
	},

	/**
	 * Add a new call note to the parent call.
	 *
	 * @param {Object} state
	 * @param {Object} note
	 * @return {void}
	 */
	[ADD_NOTE_TO_CALL](state, note) {
		const call = state.items[note.call_id]
		const notes = (call.notes || []).concat(note)

		state.items[call.id] = {
			...call,
			notes,
		}
	},

	[ADD_TO_FILTER](state, call) {
		const { id: callId = 0, is_urgent = false } = call
		const urgentFilter = state?.filters?.urgent || false
		const nonUrgent = state?.filters?.nonUrgent || false
		const providers = state?.filters?.providers || []
		const filterTypes = state?.filters?.types || []
		const filterStatuses = state?.filters?.statuses || []
		const filterMeta = state?.filters?.meta || {}
		const startDateFilter = state?.filters?.starts || null
		const endDateFilter = state?.filters?.ends || null

		function areArraysEqual(array1, array2) {
			if (array1.length !== array2.length) {
				return false
			}
			const sortedArray1 = array1.slice().sort()
			const sortedArray2 = array2.slice().sort()

			return sortedArray1.join() === sortedArray2.join()
		}

		const appliedFilter = {}

		if (urgentFilter) {
			appliedFilter.isUrgent = true
		}
		if (nonUrgent) {
			appliedFilter.isUrgent = false
		}
		if (urgentFilter && nonUrgent) {
			delete appliedFilter.isUrgent
		}
		if (filterStatuses.length > 0) {
			appliedFilter.statuses = filterStatuses
		}
		if (
			appliedFilter.statuses &&
			appliedFilter.statuses.includes(DISMISS_FILTER) &&
			appliedFilter.statuses.includes(INCOMPLETE_FILTER) &&
			appliedFilter.statuses.includes(COMPLETE_FILTER)
		) {
			appliedFilter.statuses = appliedFilter.statuses.filter(
				status =>
					status !== INCOMPLETE_FILTER &&
					status !== DISMISS_FILTER &&
					status !== COMPLETE_FILTER
			)
		}
		if (
			appliedFilter.statuses &&
			appliedFilter.statuses.includes(COMPLETE_FILTER) &&
			appliedFilter.statuses.includes(INCOMPLETE_FILTER)
		) {
			appliedFilter.statuses = appliedFilter.statuses.filter(
				status =>
					status !== INCOMPLETE_FILTER && status !== COMPLETE_FILTER
			)
		}
		if (
			appliedFilter.statuses &&
			appliedFilter.statuses.includes(DISMISS_FILTER) &&
			appliedFilter.statuses.includes(INCOMPLETE_FILTER)
		) {
			appliedFilter.statuses = appliedFilter.statuses.filter(
				status =>
					status !== INCOMPLETE_FILTER && status !== DISMISS_FILTER
			)
		}
		if (providers.length > 0) {
			appliedFilter.providers = providers
		}
		if (filterTypes.length > 0) {
			appliedFilter.types = filterTypes.map(type => type.id)
		}
		const callAttrib = {}
		const callStatuses = []
		if (call.completed_at) {
			callStatuses.push(COMPLETE_FILTER)
		} else {
			callStatuses.push(INCOMPLETE_FILTER)
		}
		if (call.notes.length > 0) {
			callStatuses.push(NOTED_FILTER)
		}
		if (call.has_fallback_event) {
			callStatuses.push('fallback')
		}
		const appliedFilterKeys = Object.keys(appliedFilter)
		if (appliedFilterKeys.includes('isUrgent')) {
			callAttrib.isUrgent = is_urgent
		}

		if (appliedFilterKeys.includes('statuses')) {
			callAttrib.statuses = callStatuses
		}

		if (appliedFilterKeys.includes('providers')) {
			callAttrib.providers =
				[call?.provider?.id || call?.patient_provider?.id] || []
		}

		if (appliedFilterKeys.includes('types')) {
			callAttrib.types = call?.types?.map(type => type.id) || []
		}

		const isValid = []
		appliedFilterKeys.forEach(key => {
			if (
				typeof appliedFilter[key] === 'boolean' &&
				appliedFilter[key] === callAttrib[key]
			) {
				isValid.push(key)
			}

			if (
				Array.isArray(appliedFilter[key]) &&
				appliedFilter[key].every(element =>
					callAttrib[key].includes(element)
				)
			) {
				isValid.push(key)
			}

			if (
				key === 'providers' &&
				appliedFilter[key].includes(callAttrib.providers[0])
			) {
				isValid.indexOf(key) === -1 && isValid.push(key)
			}

			if (
				key === 'types' &&
				appliedFilter[key].some(type => callAttrib.types.includes(type))
			) {
				isValid.indexOf(key) === -1 && isValid.push(key)
			}
		})
		const callCreateDate = moment
			.utc(call.created_at, 'YYYY-MM-DD HH:mm:ss')
			.local()
			.format('YYYY-MM-DD')
		const startDate = moment(startDateFilter)
		const endDate = moment(endDateFilter)

		const dateToCheck = moment(callCreateDate)

		if (startDateFilter && endDateFilter) {
			if (dateToCheck.isBetween(startDate, endDate, null, '[]')) {
				if (areArraysEqual(appliedFilterKeys, isValid)) {
					if (!state.filters.ids.includes(callId)) {
						state.filters.ids.unshift(callId)
						state.filters.meta = {
							...filterMeta,
							total: filterMeta.total + 1,
						}
					}
				}
			}
		} else {
			if (areArraysEqual(appliedFilterKeys, isValid)) {
				if (!state.filters.ids.includes(callId)) {
					state.filters.ids.unshift(callId)
					state.filters.meta = {
						...filterMeta,
						total: filterMeta.total + 1,
					}
				}
			}
		}
		state.meta = {
			...state.meta,
			total: state.meta.total + 1,
		}
	},

	[UPDATE_TO_FILTER](state, call) {
		const { id: callId = 0, is_urgent = false } = call
		const urgentFilter = state?.filters?.urgent || false
		const nonUrgent = state?.filters?.nonUrgent || false
		const filterTypes = state?.filters?.types || []
		const filterStatuses = state?.filters?.statuses || []
		const filterMeta = state?.filters?.meta || {}
		const providers = state?.filters?.providers || []
		const startDateFilter = state?.filters?.starts || null
		const endDateFilter = state?.filters?.ends || null

		function areArraysEqual(array1, array2) {
			if (array1.length !== array2.length) {
				return false
			}
			const sortedArray1 = array1.slice().sort()
			const sortedArray2 = array2.slice().sort()

			return sortedArray1.join() === sortedArray2.join()
		}

		if (
			urgentFilter &&
			!nonUrgent &&
			state.filters.ids.includes(callId) &&
			!is_urgent
		) {
			state.filters.ids = state.filters.ids.filter(id => id !== callId)
			state.filters.meta = {
				...filterMeta,
				total: filterMeta.total - 1,
			}
		}

		if (
			filterStatuses.length > 0 &&
			filterStatuses.includes(INCOMPLETE_FILTER)
		) {
			if (call.completed_at && state.filters.ids.includes(callId)) {
				state.filters.ids = state.filters.ids.filter(
					id => id !== callId
				)
				state.filters.meta = {
					...filterMeta,
					total: filterMeta.total - 1,
				}
			}
		}

		if (providers.length > 0) {
			if (!providers.includes(call?.provider?.id)) {
				if (state.filters.ids.includes(callId)) {
					state.filters.ids = state.filters.ids.filter(
						id => id !== callId
					)
					state.filters.meta = {
						...filterMeta,
						total: filterMeta.total - 1,
					}
				}
			}
		}

		const appliedFilter = {}

		if (urgentFilter) {
			appliedFilter.isUrgent = true
		}
		if (nonUrgent) {
			appliedFilter.isUrgent = false
		}
		if (urgentFilter && nonUrgent) {
			delete appliedFilter.isUrgent
		}
		if (filterStatuses.length > 0) {
			appliedFilter.statuses = filterStatuses
		}
		if (
			appliedFilter.statuses &&
			appliedFilter.statuses.includes(COMPLETE_FILTER) &&
			appliedFilter.statuses.includes(INCOMPLETE_FILTER)
		) {
			appliedFilter.statuses = appliedFilter.statuses.filter(
				status =>
					status !== INCOMPLETE_FILTER && status !== COMPLETE_FILTER
			)
		}
		if (providers.length > 0) {
			appliedFilter.providers = providers
		}
		if (filterTypes.length > 0) {
			appliedFilter.types = filterTypes.map(type => type.id)
		}

		const callAttrib = {}
		const callStatuses = []
		if (call.completed_at) {
			callStatuses.push(COMPLETE_FILTER)
		} else {
			callStatuses.push(INCOMPLETE_FILTER)
		}
		if (call.notes.length > 0) {
			callStatuses.push(NOTED_FILTER)
		}
		if (call.has_fallback_event) {
			callStatuses.push('fallback')
		}

		const appliedFilterKeys = Object.keys(appliedFilter)
		if (appliedFilterKeys.includes('isUrgent')) {
			callAttrib.isUrgent = is_urgent
		}

		if (appliedFilterKeys.includes('statuses')) {
			callAttrib.statuses = callStatuses
		}
		if (appliedFilterKeys.includes('providers')) {
			callAttrib.providers =
				[call?.provider?.id || call?.patient_provider?.id] || []
		}
		if (appliedFilterKeys.includes('types')) {
			callAttrib.types = call?.types?.map(type => type.id) || []
		}

		const isValid = []
		appliedFilterKeys.forEach(key => {
			if (
				typeof appliedFilter[key] === 'boolean' &&
				appliedFilter[key] === callAttrib[key]
			) {
				isValid.push(key)
			}

			if (
				Array.isArray(appliedFilter[key]) &&
				appliedFilter[key].every(element =>
					callAttrib[key].includes(element)
				)
			) {
				isValid.push(key)
			}

			if (
				key === 'providers' &&
				appliedFilter[key].includes(callAttrib.providers[0])
			) {
				isValid.indexOf(key) === -1 && isValid.push(key)
			}
		})

		const callCreateDate = moment
			.utc(call.created_at, 'YYYY-MM-DD HH:mm:ss')
			.local()
			.format('YYYY-MM-DD')
		const startDate = moment(startDateFilter)
		const endDate = moment(endDateFilter)

		const dateToCheck = moment(callCreateDate)
		if (startDateFilter && endDateFilter) {
			if (dateToCheck.isBetween(startDate, endDate, null, '[]')) {
				if (areArraysEqual(appliedFilterKeys, isValid)) {
					if (!state.filters.ids.includes(callId)) {
						state.filters.ids.unshift(callId)
						state.filters.meta = {
							...filterMeta,
							total: filterMeta.total + 1,
						}
					}
				}
			}
		} else {
			if (areArraysEqual(appliedFilterKeys, isValid)) {
				if (!state.filters.ids.includes(callId)) {
					state.filters.ids.unshift(callId)
					state.filters.meta = {
						...filterMeta,
						total: filterMeta.total + 1,
					}
				}
			}
		}
	},

	/**
	 * Add the given recent call to the store.
	 *
	 * @param {Object} state
	 * @param {Number} id
	 * @return {void}
	 */
	[ADD_RECENT_CALL](state, id) {
		if (state.recentCalls.indexOf(id) === -1) {
			state.recentCalls.push(id)
		}
	},

	/**
	 * Merge the given completed call with the respective call in the store.
	 *
	 * @param {Object} state
	 * @param {Object} call
	 * @return {void}
	 */
	[COMPLETE_CALL](state, call) {
		helpers.add(state, [call])
	},

	/**
	 * Merge the given completed call with the respective call in the store.
	 *
	 * @param {Object} state
	 * @param {Object} call
	 * @return {void}
	 */
	[DISMISS_CALL](state, call) {
		helpers.add(state, [call])
	},

	/**
	 * Remove all the recent calls from the store.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[REMOVE_ALL_RECENT_CALLS](state) {
		state.recentCalls = []
	},

	/**
	 * Remove a model from the module by id.
	 *
	 * @param {Object} state
	 * @param {Number} id
	 * @return {void}
	 */
	[REMOVE_BY_ID](state, id) {
		helpers.removeById(state, id)
	},

	/**
	 * Remove the given status.
	 *
	 * @param {Object} state
	 * @param {String} callType
	 * @return {void}
	 */
	[REMOVE_CALL_TYPE_FILTER](state, callType) {
		const index = state.filters.types.findIndex(
			type => type.id === callType.id
		)

		if (index > -1) {
			state.filters.types.splice(index, 1)
		}
	},

	/**
	 * Remove the current date range filter from the store.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[REMOVE_DATE_RANGE_FILTER](state) {
		state.filters.starts = null
		state.filters.ends = null

		state.filters.ids = []
	},

	/**
	 * Delete a note from an specific call.
	 *
	 * @param {Object} state
	 * @param {Object} note
	 * @return {void}
	 */
	[REMOVE_NOTE_FROM_CALL](state, note) {
		const index = state.items[note.call_id].notes.findIndex(
			item => item.id === note.id
		)

		if (index > -1) {
			state.items[note.call_id].notes.splice(index, 1)
		}
	},

	/**
	 * Remove the given recent call id from the store.
	 *
	 * @param {Object} state
	 * @param {String} status
	 * @return {void}
	 */
	[REMOVE_RECENT_CALL](state, id) {
		const index = state.recentCalls.indexOf(id)

		if (index !== -1) {
			state.recentCalls.splice(index, 1)
		}
	},

	/**
	 * Remove the given status.
	 *
	 * @param {Object} state
	 * @param {String} status
	 * @return {void}
	 */
	[REMOVE_STATUS_FILTERS](state, status) {
		const index = state.filters.statuses.indexOf(status)

		state.filters.statuses.splice(index, 1)
	},

	/**
	 * Remove the current search term.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[REMOVE_SEARCH_FILTER](state) {
		state.filters.search = ''
	},

	/**
	 * Reset the given state back to its original value.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[RESET](state) {
		helpers.reset(state, stateFactory())
	},

	/**
	 * Reset the given filters state back to its original state.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[RESET_FILTERS](state) {
		state.filters.ids = []

		state.filters.meta = {}
	},

	/**
	 * Set the call's ends time filter.
	 *
	 * @param {Object} state
	 * @param {String} ends
	 * @return {void}
	 */
	[SET_ENDS_TIME_FILTER](state, ends) {
		state.filters.ends = ends
	},

	/**
	 * Set the call's start time filter.
	 *
	 * @param {Object} state
	 * @param {String} starts
	 * @return {void}
	 */
	[SET_STARTS_TIME_FILTER](state, starts) {
		state.filters.starts = starts
	},

	/**
	 * Set the given model's filtered providers.
	 *
	 * @param {Object} state
	 * @param {Array} providers
	 * @return {void}
	 */
	[SET_CALL_PROVIDER_FILTERS](state, providers) {
		state.filters.providers = [...[].concat(providers)]
	},

	/**
	 * Set the given model's filtered call types.
	 *
	 * @param {Object} state
	 * @param {Array} types
	 * @return {void}
	 */
	[SET_CALL_TYPE_FILTERS](state, types) {
		state.filters.types = [...[].concat(types)]
	},

	/**
	 * Set the given model's filtered items.
	 *
	 * @param {Object} state
	 * @param {Array} statuses
	 * @return {void}
	 */
	[SET_STATUS_FILTERS](state, statuses) {
		state.filters.statuses = [...[].concat(statuses)]
	},

	/**
	 * Set the filter ids from the given model.
	 *
	 * @param {Object} state
	 * @param {Array} models
	 * @return {void}
	 */
	[SET_FILTER_IDS](state, models) {
		if (models.length === 0) {
			return
		}

		models.forEach(model => {
			if (!state.filters.ids.includes(model.id)) {
				state.filters.ids.push(model.id)
			}
		})
	},

	/**
	 * Set the model filter's meta data.
	 *
	 * @param {Object} state
	 * @param {Object} meta
	 * @return {void}
	 */
	[SET_FILTER_META](state, meta) {
		state.filters.meta = {
			...meta,
		}
	},

	/**
	 * Set the incomplete call count.
	 *
	 * @param {Object} state
	 * @param {Object} options
	 * @return {void}
	 */
	[SET_INCOMPLETE_CALL_COUNT](
		state,
		{ urgent = 0, non_urgent = 0, total = 0, setTotal }
	) {
		if (setTotal) {
			state.incomplete.total = total
		}
		state.incomplete.urgent = urgent
		state.incomplete.nonUrgent = non_urgent
	},

	/**
	 * Replace the module's state with the given models.
	 *
	 * @param {Object} state
	 * @param {Array} models
	 * @return {void}
	 */
	[SET_ITEMS](state, models) {
		state.ids = []
		state.items = {}

		helpers.add(state, models)
	},

	/**
	 * Set the model meta data.
	 *
	 * @param {Object} state
	 * @param {Object} meta
	 * @return {void}
	 */
	[SET_META](state, meta) {
		state.meta = {
			...meta,
		}
	},

	/**
	 * Set the non-urgent call filter.
	 *
	 * @param {Object} state
	 * @param {Boolean} nonUrgent
	 * @return {void}
	 */
	[SET_NON_URGENT_CALL_FILTER](state, nonUrgent) {
		state.filters.nonUrgent = nonUrgent
	},

	/**
	 * Set the call's search term.
	 *
	 * @param {Object} state
	 * @param {String} term
	 * @return {void}
	 */
	[SET_SEARCH_FILTER](state, term) {
		state.filters.search = term
	},

	/**
	 * Set the urgent call filter.
	 *
	 * @param {Object} state
	 * @param {Boolean} urgent
	 * @return {void}
	 */
	[SET_URGENT_CALL_FILTER](state, urgent) {
		state.filters.urgent = urgent
	},

	/**
	 * Update the given call attribute's value.
	 *
	 * @param {Object} state
	 * @param {Number} payload.id
	 * @param {String} payload.key
	 * @param {*} payload.value
	 * @return {void}
	 */
	[UPDATE_CALL_ATTRIBUTE](state, { id, key, value }) {
		state.items[id] = {
			...state.items[id],
			[key]: value,
		}
	},

	/**
	 * Update the given call note.
	 *
	 * @param {Object} state
	 * @param {Object} note
	 * @return {void}
	 */
	[UPDATE_CALL_NOTE](state, note) {
		const call = state.items[note.call_id]
		const index = call.notes.findIndex(item => item.id === note.id)

		if (index === -1) {
			return
		}

		const call_note = {
			...call.notes[index],
			call_id: note.call_id,
			call_sid: call.sid,
			created_at: note.created_at,
			content: note.content,
			updated_at: note.updated_at,
			user: note.user,
		}

		if (index > -1) {
			call.notes.splice(index, 1, call_note)
		}
	},
	[SET_LOADING](state, loading) {
		state.loading = loading
	},
}
