/*
|--------------------------------------------------------------------------
| API Call Filters
|--------------------------------------------------------------------------
|
| The constants below represent the filters that can be used to filter
| API requests to retrieve call models from the database.
|
*/
export const CALLS_FILTER = 'calls_only'
export const COMPLETE_FILTER = 'complete'
export const FULL_VISIBILITY_FILTER = 'full_visibility_only'
export const INCOMPLETE_FILTER = 'incomplete'
export const NOTED_FILTER = 'noted'
export const PAGING_FILTER = 'paging_only'
export const DISMISS_FILTER = 'dismiss'

/**
 * The available call service API query filters.
 *
 * @type {Array}
 */
export const filters = [
	COMPLETE_FILTER,
	FULL_VISIBILITY_FILTER,
	INCOMPLETE_FILTER,
	NOTED_FILTER,
	PAGING_FILTER,
	DISMISS_FILTER,
]

/*
|--------------------------------------------------------------------------
| API Call Statuses
|--------------------------------------------------------------------------
|
| The constants below represent the statuses that an indididual call model
| can be assigned. These statuses only reference the actual pyhsical phone
| call status from Twilio.
|
*/
export const ARCHIVED_STATUS = { name: 'archived', label: 'Archived' }
export const COMPLETE_STATUS = { name: 'complete', label: 'Complete' }
export const DISMISS_STATUS = { name: 'dismiss', label: 'Dismiss' }
export const IN_PROGRESS_STATUS = { name: 'in-progress', label: 'In Progress' }
export const MISSED_STATUS = { name: 'missed', label: 'Missed' }
export const NEW_STATUS = { name: 'new', label: 'New' }
export const NOTIFICATIONS_SENT_STATUS = {
	name: 'notifications-sent',
	label: 'Notifications Sent',
}
export const RESPONDED_STATUS = { name: 'responded', label: 'Responded' }

/**
 * The available call status API query scope values.
 *
 * @type {Array}
 */
export const statuses = [
	ARCHIVED_STATUS,
	COMPLETE_STATUS,
	DISMISS_STATUS,
	IN_PROGRESS_STATUS,
	MISSED_STATUS,
	NEW_STATUS,
	NOTIFICATIONS_SENT_STATUS,
	RESPONDED_STATUS,
]
